import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0a986d",
    },
    secondary: {
      main: "#0c3d19",
    },
  },
});

const MainRoutes = lazy(() => import("./MainRoutes"));
const VerifyUserEmail = lazy(() =>
  import("./routes/verifyEmail/VerifyUserEmail")
);
const AdminRouter = lazy(() => import("./routes/admin/adminRouter"));
const VerifyAccess = lazy(() => import("./routes/subUsers/VerifyAccess"));

const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<div>loading...</div>}>
          <Switch>
            <Route path="/verifyEmail/:id" component={VerifyUserEmail} />
            <Route path="/verifyAccess/:id" component={VerifyAccess} />
            <Route path="/admin" component={AdminRouter} />
            <Route>
              <GoogleOAuthProvider clientId="681265359919-ppmgdg0lkv5kgpc4rl49dirhf36tmmvg.apps.googleusercontent.com">
                <MainRoutes />
              </GoogleOAuthProvider>
            </Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
